import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय २१ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय २१ वा' num='21'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे विधात्या परमेश्वरा । तुझा वास चराचरा । माझ्यावर कृपावर्षाव करा ॥ १ ॥
यावे तू माझ्या स्थाना । सहाय्य व्हावे ग्रंथलेखना । सहाय्य हो तू पूर्ण करण्या । पूर्णत्वातच शांती मना ॥ २ ॥
गुरू मंदिर झाले । कळस बाकी राहिले । मुख्यच बाकी राहिले । ते पूर्ण करण्या पुन्हा विनविले ॥ ३ ॥
जैसे गुरूलीला मंदिर सुंदर । तैसाच कळस होवो सुंदर । कळसावीण मंदीर । कैसे शोभून दिसणार ? ॥ ४ ॥
इतुके केलेस सहाय्य । आता थोडक्यासाठी आर्जव । पुन्हा पुन्हा हा विनवे जीव । येऊ दे तुला कीव ॥ ५ ॥
ठेवू नकोस तू अर्धवट । जेणे ठरेन मी अर्धवट । ऐसा नको प्रसंग बिकट । पूर्ण करावे अर्धवट ॥ ६ ॥
एकवेळ परवडेल अज्ञानी । परी न परवडणार अर्धवट ज्ञानी । लाथाडतील मजलागुनी । निंदा नालस्ती करुनी ॥ ७ ॥
अर्धवटास जगणे भारी । जो तो निर्भत्सना करी । होईन भार भुमीवरी । त्यापेक्षा मरण यावे सत्वरी ॥ ८ ॥
कोण मज पत्करेल ? । तुलाच उद्धरावे लागेल । ग्रंथ पूर्ण करावा लागेल । अर्धवट टाकून कैसे चालेल ? ॥ ९ ॥
पूर्ण होण्यातच तुझी वाहवा । जेणे सहाय्यभूत व्हावा । पुन्हा पुन्हा विचार करावा । मला जवळ करावा ॥ १० ॥
सर्वजण तिष्ठत बसले । ग्रंथपूर्तीत उत्सुकलेले । सभोवतालीच बसले । पूर्तीची वाट पाहू लागले ॥ ११ ॥
नुसते नको आश्वासन । लाभू दे तुझे चरण । वाट पहाती भक्तगण । होऊ दे मनोरथ पूर्ण ॥ १२ ॥
ऐसा करावा कळस । जे स्मरतील तास नि तास । तेणे गुरूसहवास । तूच पात्र ठरशील स्तुतीस ॥ १३ ॥
एकोणिसाव्या पर्यंत । हयातीच्या लीला येतात । विसाव्या अध्यायात । हयातीनंतरच्या येतात ॥ १४ ॥
एकविसाव्या अध्यायात । सर्वांचे सार त्यात । लक्ष गुंतू दे लेखनात । जेणे पदरचे न येईल त्यात ॥ १५ ॥
ज्याला वाटे भेटावा भगवंत । त्याने आधी आळवावा संत । संत भेटतो कशात ? । हे ही सांगतो भगवंत ॥ १६ ॥
नाम ज्याच्या मुखात । संत त्याच्या दारात । भगवंत त्याच्या दारात । तेणे नाम असावे मुखात ॥ १७ ॥
संतांस आवडे नाम सहवास । नामातच घालवती अगणित तास । तेच तारतात भक्तास । लावतात सन्मार्गास ॥ १८ ॥
असेच होते संत गजानन । मुखी त्यांच्या नामस्मरण । तारतात भक्तगण । जे त्यांचे राहती होऊन ॥ १९ ॥
नामजप इतुका करावा । जेणे गुरू गजानन तोषावा । त्यानेच जवळ करावा । सहाय्यभूत व्हावा ॥ २० ॥
चिंतन करावे नामाचे । नच नुसत्या वैभवाचे । होऊन रहावे नामाचे । जेणे व्हाल गजाननाचे ॥ २१ ॥
नामजप आत्मा समजा । वैभवास देह समजा । नाम शाश्वत समजा । देह अशाश्वत समजा ॥ २२ ॥
आत्म्यास महत्व फार । आत्माच राहे अमर । देह हा नश्वर । हेच ठसवा मनावर ॥ २३ ॥
ऐसे प्रयत्न करावे । गुरूकृपेस पात्र ठरावे । गण गण गणात बोते जपावे । तेणे गुरू दर्शन व्हावे ॥ २४ ॥
गुरूलीलेस स्मरावे । तेच आधार मानावे । नामजप ऐसे करावे । प्रारब्ध सुसह्य व्हावे ॥ २५ ॥
समर्थ गजाननाच्या अनेक लीला । वाचण्यात आनंद मनाला । अजूनही चमत्कृती लीला । येतात अनुभवाला ॥ २६ ॥
महाराज प्रकटले शेगावास । माघ वद्य सप्तमीस । ऐन दुपारच्या समयास । पातुरकराच्या घरास ॥ २७ ॥
पातुरकराच्या घरात । होती सुनेची ऋतुशांत । वाटे सुसंतती नांदावी घरात । सुसंस्कार टिकावे घरात ॥ २८ ॥
कर्मफळे अवलंबती कर्मांवर । सत्वृत्तीची सत्फळे मिळणार । सुसंस्कारी सत्शील पातुरकर । जेणे दारी प्रकटला योगेश्वर ॥ २९ ॥
पत्रावळीची शीते । वेचून खाण्यात मग्न होते । अन्न परब्रह्म पटवत होते । त्यांच्या कृतीत दिसत होते ॥ ३० ॥
बंकटे दामोदरे ओळखले त्यास । वाळ्याचे पाणी देता पिण्यास । गेला हाळाचे पाणी पिण्यास । गढुळ निर्मळ सारखे त्यास ॥ ३१ ॥
नाल्यात तुंब्या भरला । पितांबरास चमत्कार दावला । गोविंदबुवाच्या कीर्तनाला । उत्तरार्ध वदला ॥ ३२ ॥
गोविंदबुवे जाणले अधिकाराला । समर्थांस उच्चासन देता झाला । बळी न पडले आग्रहाला । उपदेश केला गोविंदबुवाला ॥ ३३ ॥
अती आग्रह फार वाईट । पटवते झाले समर्थ । पटवते झाले एकेक कृतीत । ह्या कथा द्वितीय अध्यायात ॥ ३४ ॥
काशीच्या गोसाव्याने नवस केला । समर्थांस गांजा देता झाला । समर्थे स्विकार केला । न दुखविले गोसाव्याला ॥ ३५ ॥
जानराव देशमुखाला । गंडांतराचा योग आला । तीर्थ अंगारा उपयुक्त ठरला । टाळले गंडांतराला ॥ ३६ ॥
विठोबा घाटोळ ढोंगी भक्त । त्याच्या वशिल्यास झिडकारतात । त्याला झोडपून काढतात । ह्या कथा तृतीयाध्यात ॥ ३७ ॥
ठिणगीवाचून चिलिमीस पेटवले । पंचमहाभूतातील तत्व निर्मिले । चंदू मुकिनाच्या घरचे कानवले । अंतर्ज्ञाने ओळखले ॥ ३८ ॥
योग्य कर्म योग्य वेळेस । माधवास उपदेश । आले ब्राह्मण वसंतपूजेस । ह्या कथा चवथ्या अध्यायास ॥ ३९ ॥
पिंपळगावात महाराज गेले । योगी पुरूष पटवले । कोरड्या विहिरीत जल उत्पन्न केले । पाचव्या अध्यायी आले ॥ ४० ॥
जशास तसे न वागावे । हेच मनावर ठसवावे । गांधिलमाशांनी समर्थांस चावावे । समर्थे निमूटपणे सहन करावे ॥ ४१ ॥
नरसिंगास भेटले । मनोमन खूष झाले । व्रजभूषणा तपोबल पटवले । सहाव्या अध्यायी आले ॥ ४२ ॥
हरी पाटलाशी कुस्ती खेळले । योग सामर्थ्याचे बळ पटवले । पाटिलांनी ऊसांनी बडवले । तरी पाठीवर एकही वळ न उठले ॥ ४३ ॥
पाटिलांस ऊसाचा रस देते झाले । विशाल अंतःकरण दावले । गुरूकृपेने खंडूस पुत्ररत्न झाले । प्रसंग सातव्या अध्यायी आले ॥ ४४ ॥
गुरूकृपे संकटमुक्त । खंडू पाटिल बेडीमुक्त । महाराज वेद पारंगत । तेलंगी ब्राह्मण आश्चर्यचकित ॥ ४५ ॥
आधी करावे मग सांगावे । उगाच कीर्तन तमाशा नसावे । वाटे ब्रह्मगिरीने जागे व्हावे । विपरित प्रसंग न भोगावे ॥ ४६ ॥
ठिणगी पडली पलंगावर । पलंग पेटला सत्वर । महाराज तसेच पलंगावर । आठव्या अध्यायात कथासार ॥ ४७ ॥
द्वाड घोडे शांत केले । चौखुरात झोपले । गोविंदबुवास जागृत केले । नामजपाचे महत्व पटवले ॥ ४८ ॥
“तो” “मी” नसे वेगळा । बाळकृष्णास उपदेश केला । रामदासरूपात योगी प्रकटला । कथा नवव्या अध्यायाला ॥ ४९ ॥
गणेश अप्पांची पूजा स्विकारतात । अंतर्ज्ञानाची खूण पटवतात । स्पर्धा नसावी भक्तीत । होते त्यात विपरीत ॥ ५० ॥
गायीचे आडदांडपणे । शांत केले योगलीलेने । लक्ष्मणाचे पोटदुखणे । बरे केले उष्ट्या आंब्याने ॥ ५१ ॥
नको आचरू दांभिकपणास । पटवले लक्ष्मणास । तेणेच दारिद्र्य योग त्यास । कथा दहाव्या अध्यायास ॥ ५२ ॥
पूर्वसंचितानुसार । दिला भास्करास मार । कावळ्यांस शिकवला वेदसार । नको द्वेष मत्सर आचार ॥ ५३ ॥
गणू जवर्‍यास वाचवले । सुरुंगातून बाहेर काढले । त्याचे गंडांतर टाळले । प्रसंग अकराव्या अध्यायी आले ॥ ५४ ॥
ज्याला जे दिल्यास । संतोष होतो त्यास । झिडकारले अलंकारास । पटवले बच्चूलालास ॥ ५५ ॥
वठलेल्या आंब्यास पालवी फुटली । गुरूकृपेची खूण पटवली । करारापेक्षा जास्त जागा घेतली । सरकारी दंडाची माफी झाली ॥ ५६ ॥
दंडाची रक्कम परत मिळाली । चमत्कृतीची खूण पटवली । पितांबरास गुरूकृपा झाली । बाराव्या अध्यायी वर्णिली ॥ ५७ ॥
गुरूकृपा योग गंगाभारतीला । बरे केले महारोगाला । पर्जन्याचा गोंधळ टाळला । झ्यामसिंगाचा भंडारा निर्विघ्न झाला ॥ ५८ ॥
प्लेगची गाठ पुंडलीकास । मुक्त केले दुखण्यास । बरे करतात रोगास । कथा तेराव्यास ॥ ५९ ॥
बंडूतात्यास कर्ज झाले । सावकारी व्याज सुरू झाले । होते नव्हते ते सर्व गेले । पुन्हा त्यास पूर्वव्रत केले ॥ ६० ॥
ओंकारेश्वरी दर्शनास । घडले विपरीत प्रसंगास । प्रत्यक्ष नर्मदा आली दर्शनास । तीने रक्षिले सर्वांस ॥ ६१ ॥
जेवून गेले माधवनाथ । विडा येथेच विसरतात । वानवळ्यास पटवतात । कथा चवदाव्यात ॥ ६२ ॥
कृपाशिर्वाद लोकमान्यांस । अमर झाले गीतारहस्य । प्रारब्ध भोग न सुटला कुणास । सुचवले कोल्हटकरास ॥ ६३ ॥
श्रीधर काळे गोविंदास । केला उपदेश त्यास । नको जाऊ विलायतेस । कथा पंधराव्यास ॥ ६४ ॥
दिल्या पादुका पुंडलीकास । खरा गुरू दावला त्यास । थांबले तिसर्‍या प्रहरास । भाऊ कवराच्या प्रसादास ॥ ६५ ॥
तुक्याच्या कानात छरा गेला । मठ झाडल्याने बरा झाला । गुरूसेवेचा महिमा पटवला । कथा सोळाव्या अध्यायाला ॥ ६६ ॥
फिर्याद झाली नागवेपणावर । जिंकली योगसामर्थ्यावर । निकाल देतात जठार । भास्कर ठरला गुन्हेगार ॥ ६७ ॥
आडदांडपणा करू नये । लोकांना छळू नये । भूमीवर भार होऊ नये । महताबशास पटवे ॥ ६८ ॥
बापूरावाच्या पत्नीस । भानामतीची पीडा तीस । अंगार्‍याने बरे केले तीस । गुरूकृपा योग तीस ॥ ६९ ॥
महाराज गेले अकोटास । भेटले नरसिंगास । झरे उत्पन्न विहिरीस । कथा सतराव्यास ॥ ७० ॥
बायजेची अब्रूरक्षण । बापुन्यास विठ्ठल दर्शन । तीर्थ अंगारा भाऊस देऊन । फोड दिला बरा करून ॥ ७१ ॥
कुत्रे मेलेले रस्त्यात । पदस्पर्शाने जिवंत करतात । थोर प्रभाव स्पर्शात । ह्या कथा अठराव्यात ॥ ७२ ॥
भविष्य कथिले काशिनाथास । सांगितले बदलीच्या वृत्तास । कृपाशिर्वाद गोपाळबुटीस । पुत्ररत्न झाले त्यास ॥ ७३ ॥
आधीच ओळखतात । बाळाभाऊस सांगतात । येतात धारचे रंगनाथ । माणगावचे वासुदेवानंद येतात ॥ ७४ ॥
ज्ञान प्राप्तीचे तीन मार्ग । भक्ती, कर्म नि योग । त्यात सोपा भक्तीमार्ग । योग, कर्म कठीण मार्ग ॥ ७५ ॥
सांगितले साळूबाईस । रहावे मठात स्वयंपाकास । वेदज्ञान महाराजांस । पटवले आत्मारामास ॥ ७६ ॥
तिमाजीस जागे केले । गाढवांपासून शेत रक्षिले । हवालदाराने महाराजांस बडवले । हवालदारास मरण आले ॥ ७७ ॥
संसारसुख हरी जाखड्यास । योगविद्या निमोणकरास । हेतु जाणुनी भक्तमनास । कृपाशिषे पूर्णत्वास ॥ ७८ ॥
विसरू नये केलेला नवस । सांगितले तुकाराम कोकाटेस । स्वयेच जाणला समाधी दिवस । सांगितला हरी पाटिलास ॥ ७९ ॥
बाळाभाऊस गादीवर बसवतात । संपले आमचे कार्य म्हणतात । ऋषीपंचमीस समाधीस्त । कथा एकोणिसाव्यात ॥ ८० ॥
जागे केले गणपताच्या पत्नीस । वागू द्यावे पतिच्या मर्जीस । दानधर्म करता दसर्‍यास । नाही मुकणार वस्त्रालंकारास ॥ ८१ ॥
नको कंटाळू घरच्या त्रासास । दर्शन दिले जांजळास । पूर येतो मन नदीस । वाचवले माधव मार्तंड जोशास ॥ ८२ ॥
भिकार्‍याच्या रूपात । यादवास दर्शन देतात । कापसाच्या व्यापारात । त्याचा फायदा करतात ॥ ८३ ॥
न जाऊ दिले तेल्हार्‍यास । व्यतीपात योगास । थांबवतात भाऊस । तीर्थ प्रसादास ॥ ८४ ॥
दिनकरास घेरले सोबणी रोगाने । बरा झाला शेरणी नवसाने । चंद्रभागेस झालेले दुखणे । बरे झाले तीर्थ अंगार्‍याने ॥ ८५ ॥
रामचंद्राची पत्नी वेडमुक्त झाली । मठाला प्रदक्षिणा फळाला आली । जयपुरची बाई उत्सवास आली । भूतपिशाच्च पीडा टळली ॥ ८६ ॥
तीस फुटांवरून गवंडी पडला । तत्क्षणी झेलला त्याला । योगी कुणास न दिसला । क्षणात अदृष्य झाला ॥ ८७ ॥
रामचंद्र पाटिलास । दर्शन देते झाले त्यास । दक्षिणा नको सांगितले त्यास । पहावे मठाच्या कारभारास ॥ ८८ ॥
शतचंडीत आले विघ्न । गुरूकृपे झाला निर्विघ्न । भक्तांचा शुद्ध हेतू जाणून । महाराज येतात धावून ॥ ८९ ॥
समाधीनंतर महाराज दर्शन देतात । आपल्या भक्तांस रक्षितात । श्रद्धेचे महत्व पटवतात । कथा येतात विसाव्यात ॥ ९० ॥
गणामहाराज सांगे स्वानुभवाने । सांगतो येथे खात्रीने । नामस्मरण करावे श्रद्धेने । तेणे महाराजांचे येणे ॥ ९१ ॥
जैसे दर्शन, संभाषण गणामहाराजास । तैसेच लाभो सर्वांस । हीच प्रार्थना महाराजांस । आनंद लाभुद्या सर्वांस ॥ ९२ ॥
गणामहाराज सांगे भक्तांस । ज्याला वाटे मुक्त व्हावे संकटास । त्याने आळवावे गजाननास । गण गण गणात बोते मंत्रास ॥ ९३ ॥
श्रद्धेने पाच गुरूवार करावे । शेगावी गुरूंचे दर्शन घ्यावे । शेगावच्या पाच वार्‍या करावे । तेणे संकटमुक्त व्हावे ॥ ९४ ॥
बोलू नये दुसर्‍याशी पारायणात । चित्त ठेवावे गजाननात । पारायण ठरविलेल्या संकल्पात । पूर्ण होण्या महत्व त्यात ॥ ९५ ॥
मनाची तळमळ असल्यावर । का न संकल्प पूर्ण होणार ? । लागतो मनोनिर्धार । ठरतो गुरूंचा आधार ॥ ९६ ॥
ऐसे पारायण करावे । गुरू गजानने स्वयेच दर्शन द्यावे । गुरू सहाय्य लाभावे । शुद्ध हेतू पूर्ण व्हावे ॥ ९७ ॥
पीठले झुणका भाकर । गुलाब फुले नि वार गुरूवार । महाराजांस प्रिय फार । परी श्रद्धेला महत्व फार ॥ ९८ ॥
विसरू नका गणपती सरस्वतीस । दृष्टांत दिला गणामहाराजास । आज्ञा गणामहाराजास । लिहावे गुरू गजानन लीला ग्रंथास ॥ ९९ ॥
१९८७ माघ वद्य सप्तमीस । गुरू प्रकट दिनास । दिनांक २० फेब्रुवारी १९८७ स । आज्ञा झाली ग्रंथलेखनास ॥ १०० ॥
मातापिता कुलदेवतांचे आशिर्वाद । प. पू. योगीराज समर्थ गजाननाचे आशिर्वाद । प. पू. पांडुरंग शास्त्री आठवले गुरूंचे आशिर्वाद । सदा पाठीशी निर्विवाद ॥ १०१ ॥
पत्नी सौ. सुनीतीच्या सहाय्याविना । न झाले ग्रंथलेखन । पुत्र देवेन्द्र कन्या प्रज्ञा । देती संमती ग्रंथलेखना ॥ १०२ ॥
बंधू चंद्रकांत (दीपक) । बंधू श्यामकांत (अनिरुद्ध) । परमभक्त मनुभाई कोठारी त्यात । श्रीमती इंदूताई नेने होत्या सप्ताहात ॥ १०३ ॥
गुरूवारी दिनांक २६ फेब्रुवारी १९८७ स । महाशिवरात्रीच्या दिवसास । सायंकाळच्या प्रहरास । ग्रंथ जातो पूर्णत्वास ॥ १०४ ॥
प. पू. राजाराम महाराज गद्रे (बडोदे) प्रथम दिवसास । प. पू. दत्तात्रेय शास्त्री प्रकाशकर (बडोदे) पूर्णाहुतीस । ह्या थोर विभूतींचे आशिष । लाभले गणामहाराजास ॥ १०५ ॥
परोक्ष अपरोक्ष सहाय्य गणामहाराजास । तेणेच ग्रंथ जातो पूर्णत्वास । जेणे आनंद सर्वांस । ह्याची जाणीव गणामहाराजास ॥ १०६ ॥
आता विनवितो गजाननास । गुरूलीला वर्णनास । काही न्यूनता असल्यास । क्षमा करावी गणामहाराजास ॥ १०७ ॥
प. पू. दासगणू कृत “गजानन विजय ग्रंथ” । “गजानन कथासार” डॉ. भिंगारकर कृत । पोथी सकळकळे कृत । ठरले ग्रंथास सहाय्यभूत ॥ १०८ ॥
दशमी, एकादशी, द्वादशीस । गुरूपुष्य नक्षत्र योगास । तसेच गुरूवारास । वाचावे एकवीस अध्यायांस ॥ १०९ ॥
जो श्रद्धेने ग्रंथ वाचणार । जो शुद्ध हेतूने वाचणार । जो तळमळीने वाचणार । त्याचा मनोरथ पूर्ण होणार ॥ ११० ॥

॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य एकविंशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
